@use "../config/" as *;
.waf-component {
    &.waf-listing {
        @extend %mb-0;
        @extend %px-1;
        @extend %py-3;
    }
}
.breadcrumb-bg-full {
    .waf-shape-head {
        .waf-head {
            margin-bottom: 0;
        }
    }
}
.waf-video-inner-listing {
    .first-list {
        @extend %mt-4;
    }
}
.waf-news-listing,
.waf-photo-listing,
.waf-video-listing {
    @extend %remove-common-gradient;
    .article {
        &-thumbnail {
            .img-box {
                &::before {
                    content: unset;
                }
            }
        }
    }
}
.waf-listing {
    @extend %common-gradient;
    @extend %common-article-content;
    @include listing-card(card);
    .layout-wrapper {
        @extend %relative;
        @extend %my-0;
    }
    .waf-head {
        padding-right: 12rem;
        @extend %mb-3;
        &::after {
            content: unset;
        }
        .title {
            @extend %mb-0;
            @extend %font-18;
            @extend %secondary-light;
        }
        .head-tab {
            li {
                border: 0;
            }
            a {
                @extend %secondary-light;
            }
        }
    }
    .waf-body {
        @extend %mx-1-neg;
    }
    .article {
        &-list {
            @extend %px-3;
            .waf-child-wrapper {
                @extend %my-3;
                @extend %mx-0;
                .waf-ad {
                    @extend %m-0;
                    @extend %p-0;
                }
            }
        }
        &-thumbnail {
            .img-box {
                &::before {
                    background: linear-gradient(180deg, hsl(var(--hsl-secondary-dark) / 0.7) 14.52%, hsl(var(--hsl-primary) / 0.9) 104.88%);
                }
            }
        }
        &-description {
            @extend %d-none;
        }
    }
}
.club-page {
    .waf-component {
        &.waf-listing {
            @extend %pt-6;
        }
    }
    .filter-section {
        .btn-more {
            @extend %white;
            @extend %club-primary-bg;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        &.waf-component {
            padding-inline: 0;
        }
        .article {
            &-list {
                @include card-count(4, var(--space-3), wrap);
                padding-inline: 0;
                .waf-child-wrapper {
                    margin: var(--space-6) 0;
                    .waf-ad {
                        padding: 0;
                    }
                }
            }
            &-thumbail {
                margin-bottom: 0;
            }
        }
        .waf-body {
            padding: 0;
            margin-inline: 0;
            &::after {
                content: unset;
            }
        }
    }
    .club-page {
        .waf-component {
            &.waf-listing {
                padding-top: var(--space-8);
            }
        }
        .waf-listing {
            .first-list {
                margin-top: var(--space-3);
            }
        }
    }
}
@media screen and (max-width: $mobile-max-width) {
    .waf-listing {}
}