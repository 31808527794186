@use "../config" as *;
.waf-listing {
    .waf-body {
        @extend %mt-6;
    }
    .first-list {
        @extend %mb-3;
        @extend %flex-column-n-n;
        @include listing-card(overlay);
        .article {
            &-title {
                @include truncate(2, 16, 24);
            }
        }
        .waf-child-wrapper {
            order: -1;
        }
    }
    .second-list {
        @include listing-card(card);
    }
    .article {
        &-item {
            width: auto;
            @extend %mb-3;
            &:last-of-type {
                @extend %mb-0;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        @include listing-card(overlay);
        .first-list {
            margin-bottom: 0;
            .waf-child-wrapper {
                width: 100%;
                margin-bottom: var(--space-3);
                grid-column: 1 / 4;
                grid-row: 1 / 2;
            }
        }
        .article {
            &-list {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(4, auto);
                gap: var(--space-3);
                overflow: visible;
            }
            &-item {
                margin-bottom: 0;
                order: unset;
                &.img-4by3 {
                    grid-row-end: span 3;
                }
                &.img-1by1 {
                    grid-row-end: span 4;
                }
            }
        }
    }
}